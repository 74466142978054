<template>
  <page-template>
    <main-block>
      <block-content>
        <card no-padding is-bordered stretch>
          <template v-slot:grouped>
            <div class="card-inner position-relative card-tools-toggle">
              <card-header :title="$t('Invoices')">
                <btn-toolbar>
                  <btn-toolbar-toggle-wrap id="invoices_toolbar">
                    <li>
                      <nk-button is-link type=""
                                 is-icon-only-button
                                 class="search-toggle"
                                 v-on:click="searchBarActive=true"
                                 :class="{active: searchBarActive}">
                        <nio-icon icon="ni-search"></nio-icon>
                      </nk-button>
                    </li>
                    <li class="btn-toolbar-sep"></li>
                    <li>
                      <drop-down
                          icon="ni-filter-alt"
                          size="lg"
                          direction="right"
                          :dot="(parseInt(statusFilter) > 0 ? 'primary':'')"
                          :title="$t('Invoices')+' '+$t('Filter')">
                        <template v-slot:body>
                          <row class="gx-6 gy-3">
                            <column default="12" md="6">
                              <label class="overline-title overline-title-alt" for="status_filter">{{$t('Status')}}</label>
                              <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                <option value="">{{ $t('All') }}</option>
                                <option v-for="status in invoiceStatuses" :key="status.id" :value="status.id">{{ $t(status.status_text)}}</option>
                              </select>
                            </column>
                          </row>
                        </template>
                        <template v-slot:footer>
                          <nk-button type="dark" dim v-on:click="getInvoices(true)">{{$t('Filter')}}</nk-button>
                        </template>
                      </drop-down>
                    </li><!-- li -->
                    <li>
                      <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                        <ul class="link-check">
                          <li><span>{{ $t('Order') }}</span></li>
                          <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getInvoices(true)]">{{ $t('ASC') }}</a></li>
                          <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getInvoices(true)]">{{ $t('DESC') }}</a></li>
                        </ul>
                      </drop-down>
                    </li><!-- li -->
                  </btn-toolbar-toggle-wrap>
                </btn-toolbar>
              </card-header>
              <card-search
                  :placeholder="$t('Search invoice by ID')"
                  :is-active="searchBarActive"
                  @do-search="searchInvoice"
                  :search-term="searchFilter"
                  @close-search="[searchBarActive=false, searchInvoice('')]"></card-search>
            </div>
            <div class="card-inner p-0">
              <div v-if="spinner" class="text-center">
                <ion-spinner></ion-spinner>
              </div>
              <template v-else>
                <ion-list v-if="$isHybridPlatform">
                  <ion-item button v-for="invoice in invoices" :key="'inv'+invoice.id"
                            v-on:click="$router.push({name: 'adminViewInvoice', params:{ id: invoice.id}})"
                            lines="full"
                            style="--inner-padding-bottom: 1rem;--inner-padding-top: 1rem;--border-color:#e3eaf5;">
                    <ion-avatar slot="start" class="user-avatar">
                      <img v-if="invoice.dp" :src="invoice.dp" alt="Avatar"/>
                      <span v-else class="text-uppercase">{{ invoice.initials }}</span>
                    </ion-avatar>
                    <div>
                      <ion-label class="fw-500" color="dark">{{ invoice.first_name+' '+invoice.last_name}}</ion-label>
                      <ion-text color="medium" class="d-block">{{ invoice.email }}</ion-text>
                      <ion-text color="medium">
                        #{{ invoice.invoice_number }}
                      </ion-text>
                    </div>
                    <ion-badge slot="end" color="light" :style="{color: invoiceStatusRender(invoice.status).color}">{{ $t(invoiceStatusRender(invoice.status).status_text) }}</ion-badge>
                  </ion-item>
                </ion-list>
                <table v-else class="table table-orders">
                  <table-head></table-head>
                  <tbody class="tb-odr-body">
                  <table-row v-for="invoice in invoices" :key="invoice.id">
                    <table-td type="info">
                        <span class="tb-odr-id">
                          <router-link :to="{name: 'adminViewInvoice', params:{ id: invoice.id}}">#{{ invoice.invoice_number}}</router-link>
                        </span>
                      <span class="tb-odr-date">{{ invoice.period_to }}</span>
                    </table-td>
                    <table-td type="info">
                      <user-small-card
                          :user-name="invoice.first_name+' '+invoice.last_name"
                          :user-email="invoice.email" :image="invoice.dp" :initials="invoice.initials">
                      </user-small-card>
                    </table-td>
                    <table-td type="amount">
                        <span class="tb-odr-total">
                          <span class="amount">&euro;{{ invoice.amount }}</span>
                        </span>
                      <span class="tb-odr-status">
                          <span class="badge" :style="{color: invoiceStatusRender(invoice.status).color}">{{ $t(invoiceStatusRender(invoice.status).status_text) }}</span>
                        </span>
                    </table-td>
                    <table-td type="action">
                      <div class="tb-odr-btns d-none d-sm-inline">
                        <nk-button size="sm" dim type="dark" class="btn-white pos-inherit" is-icon-only-button v-on:click="prepareInvoiceDownload(invoice.id)">
                          <nio-icon icon="ni-download"></nio-icon>
                        </nk-button>
                        <router-link :to="{name: 'adminViewInvoice', params:{ id: invoice.id}}" class="btn btn-dim btn-sm btn-dark pos-inherit">{{ $t('View') }}</router-link>
                      </div>
                      <router-link :to="{name: 'adminViewInvoice', params:{ id: invoice.id}}" class="btn btn-pd-auto d-sm-none">
                        <nio-icon icon="ni-chevron-right"></nio-icon>
                      </router-link>
                    </table-td>
                  </table-row>
                  </tbody>
                </table>
              </template>

            </div>
            <div class="card-inner" v-if="pagination.totalRecords > 15">
              <pagination
                  v-model="pagination.page"
                  :records="pagination.totalRecords"
                  :per-page="15"
                  @paginate="paginationCallback"
                  theme="bootstrap4"
                  :options="{chunk: 5, texts:{count: '||'}}"/>
            </div>
          </template>
        </card>
      </block-content>
    </main-block>
  </page-template>
</template>

<script>
import {IonList, IonItem, IonAvatar, IonLabel, IonText, IonBadge} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios";
import {Card} from "@core/components/cards";
import {useStore} from "vuex"

import TableHead from "./components/TableHead";
import {TableRow, TableTd} from "@/views/invoices/components"
import CardHeader from "@core/components/cards/CardHeader";
import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";
import invoiceFunctions from "../../invoices/functions"

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";
import Row from "@core/layouts/row/Row";
import Column from "@core/layouts/col/Col";

import Pagination from "v-pagination-3"
import CardSearch from "@core/components/cards/components/CardSearch";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import PageTemplate from "@core/layouts/page/PageTemplate";

export default defineComponent({
  components: {
    PageTemplate,
    UserSmallCard,
    CardSearch,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    TableTd,
    Card,
    TableHead,
    TableRow,
    BlockContent,
    MainBlock,
    Pagination,
    IonList,
    IonItem, IonAvatar, IonLabel, IonText, IonBadge,
  },
  setup() {

    let searchBarActive = ref(false)
    let searchFilter = ref('');
    let statusFilter = ref('')
    let order        = ref('asc')
    let invoices     = ref([])
    let spinner      = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      getInvoices(true)
    }

    let cancelToken = false
    const getInvoices = async (reset) => {
      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      spinner.value = true
      axios.post('/api/admin/invoices', {page: pagination.page, status: statusFilter.value, search: searchFilter.value, order: order.value},{
        cancelToken: new axios.CancelToken( c => {
          cancelToken = c
        })
      })
          .then( (resp) => {
            spinner.value = false
            if(reset){
              invoices.value = []
            }
            for(let x in resp.data.data){
              invoices.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            if(!err.__CANCEL__){
              spinner.value = false
              console.log('Invoices.vue ajax error: ', err)
            }
          })
      .then(() => {
        cancelToken = false
      })
    }

    onMounted(()=> getInvoices())

    const { invoiceStatusRender, prepareInvoiceDownload } = invoiceFunctions()

    const store = useStore()
    const invoiceStatuses = store.state.general_data.invoice_statuses

    watch(searchFilter, () => getInvoices(true))
    const searchInvoice = (search) => {
      searchFilter.value = search
    }

    return {
      invoices,
      getInvoices,
      prepareInvoiceDownload,
      invoiceStatuses,
      invoiceStatusRender,

      statusFilter,
      order,
      pagination,
      paginationCallback,
      searchFilter,
      searchBarActive,
      searchInvoice,
      spinner,
    }
  }
});

</script>
