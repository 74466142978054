<template>
  <thead class="tb-odr-head">
  <tr class="tb-odr-item">
    <th class="tb-odr-info">
      <span class="tb-odr-id">{{ $t('Invoice ID')}}</span>
      <span class="tb-odr-date d-none d-md-inline-block">{{ $t('Date')}}</span>
    </th>
    <th class="db-odr-item">
      <span>{{ $t('Advisor') }}</span>
    </th>
    <th class="tb-odr-amount">
      <span class="tb-odr-total">{{ $t('Amount') }}</span>
      <span class="tb-odr-status d-none d-md-inline-block">{{ $t('Status') }}</span>
    </th>
    <th class="tb-odr-action">&nbsp;</th>
  </tr>
  </thead>
</template>

<script>
export default {}
</script>
